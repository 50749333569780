import React from 'react';
import Layout from '../common/layouts';
import axios from "axios";
// import './investigacion.css'
import './investigacion.css';


const investigationSection = (researchSection) => {
  return (
    <>
      <h1 className={"text-orange-500"}>{researchSection.title}</h1>
      <div className={"research-section"} dangerouslySetInnerHTML={{__html: researchSection.content}}/>
    </>
  )
}



const Investigacion = ({props, data}) => {
  const [research, setResearch] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);

  if (!loaded) {
    axios({
      method: 'post',
      url: 'https://us-central1-akusmatika-31aec.cloudfunctions.net/api/v1/getResearch',
    }).then((res) => {
      setResearch(res.data);
      setLoaded(true);
    });
  }
  return (
    <Layout>
      {/*<Seo*/}
      {/*  title={`About ${data.site.siteMetadata.title}`}*/}
      {/*  description={data.markdownRemark.frontmatter.title} />*/}
      {/*<div className="relative">*/}
      {/*  <Img fluid={data.banner.childImageSharp.fluid} />*/}
      {/*  <h1*/}
      {/*    className="fw1 tc f2 display absolute dn dib-ns"*/}
      {/*    style={{bottom: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>About {data.site.siteMetadata.title}</h1>*/}
      {/*</div>*/}
      {/*<div className="mw9 center flex flex-wrap pv5-l w-100">*/}
      {/*  <div className="mw7 w-100 pa2">*/}
      {/*    <h1 className="display fw1 db lh-copy">{data.markdownRemark.frontmatter.title}</h1>*/}
      {/*    <Link to="/blog" className="dib bg-dark-gray b near-white hover-bg-mid-gray pv3 ph4 ttu tracked sans-serif no-underline mv2">Read the blog</Link>*/}
      {/*  </div>*/}
      {/*  <div className="mw7 w-100 lh-copy serif pa2 flex flex-column justify-center f4" dangerouslySetInnerHTML={{__html: data.markdownRemark.html}} />*/}
      {/*</div>*/}
      <div className={"container mx-auto"}>
        <div className={"pl-6 pt-6 pb-6 text-large bg-gray-50"}>
          {research.map((researchSection) => {
            return investigationSection(researchSection);
        })}
        </div>
      </div>
    </Layout>
  )
}

export default Investigacion;

